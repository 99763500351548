<div class="viewport-1" id="keyPress" (keyup)="keyPress($event)" tabindex="0"
  [ngStyle]="{'background-image':'url(' + interventionTaskService.taskBackgroundImage() + ')'}">
  <student-header></student-header>
  <task-bar #taskBar [totalPoints]="taskTotalPoints"></task-bar>
  <div class="task-screen-element spelling-container"
    [ngStyle]="{'background-image': ' ' + interventionTaskService.taskContainerColor() + ' '}"
    [ngClass]="(animationComplete) ? ['taskShow'] : ['taskHide']">
    <div class="testType font-18">{{interventionTaskService.testType()}}</div>
    <div class="timer-bar-container">
      <trial-timer-bar></trial-timer-bar>
      <button class="replayVideo-no-timerbar" (click)="focusThenReplayVideo()" [disabled]="disableAVButtons"></button>
    </div>
    <div class="spelling-target-area">
      <button class="speaker" (click)="playTargetAudioViaSpeakerClick()" [disabled]="disableAVButtons"></button>
      <div class="spelling-help-row">
        <button class="spelling-letter-audio-hidden" *ngIf="!showType"></button>
        <button class="spelling-letter-audio" *ngFor="let syllable of syllables; index as $index"
          [hidden]="!showType" (click)="playSyllableAudio($index)" [disabled]="disableAVButtons"></button>
      </div>
      <div class="spelling-target-row">
        <div class="spelling-target-highlight" [ngStyle]="{'width': ' ' + ((targetWord.length) * 6) + '% '}"></div>
          <div class="spelling-target-block" *ngFor="let letter of targetWord; index as $index"
            [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}">
            <div class="spelling-target-text font-30">
              {{letter}}
            </div>
          </div>
      </div>
      <div class="spelling-help-row">
        <div class="spelling-letter-space-hidden font-24" *ngIf="!showType">_</div>
        <div [hidden]="!showType" class="spelling-letter-space font-24" *ngFor="let letter of targetAnswer; index as $index">
          <div>
            {{letter.blank}}
          </div>
        </div>
      </div>
      <div class="spelling-help-row">
        <div class="letter-type-hidden font-24" *ngIf="!showType">X</div>
        <button class="font-24" [hidden]="!showType"
          [ngClass]="{'vowel' : (letter.cvc === 'V'), 'consonant' : (letter.cvc === 'C'), 'spelling-letter-space' : (letter.cvc !== 'C' && letter.cvc !== 'V')}"
          *ngFor="let letter of targetAnswer; index as $index" (click)="seeLetter($index)">
          {{letter.cvc}}
        </button>
      </div>

    </div>
    <div [ngClass]="{'keyboard-container': startTask, 'keyboard-container-hidden' : !startTask}">
      <div class="keyboard-row">
        <button [ngClass]="{'hover': letter.highlight, 'keyboard-key' : startTask, 'keyboard-key-hidden' : !startTask}"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          (click)="addLetter(letter.index)"
          (touchstart)="addHoverClass(letter)"
          (touchend)="handleTouchEndNormalKey(letter.index, $event)"
          [disabled]="disableResponseButtons"
          *ngFor="let letter of topKeyRow; index as $index">
          <div [ngClass]="{'keyboard-letters font-30' : startTask, 'keyboard-letters-hidden' : !startTask}">
            {{letter.text}}
          </div>
        </button>
        <button
          [ngClass]="{'hover': delete.highlight, 'keyboard-delete' : startTask, 'keyboard-delete-hidden' : !startTask}"
          (click)="removeLetter()"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          (touchend)="handleTouchEndSpecialKey(delete.keyCode, $event)"
          (mouseenter)="addHoverClass(delete)" (mouseleave)="removeHoverClass(delete)"
          [disabled]="disableResponseButtons">
          <div [ngClass]="{'special-text right' : startTask, 'keyboard-letters-hidden' : !startTask}">
            {{delete.text}}
          </div>
        </button>
      </div>
      <div class="keyboard-row">
        <button [ngClass]="{'hover': letter.highlight, 'keyboard-key' : startTask, 'keyboard-key-hidden' : !startTask}"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          (click)="addLetter(letter.index)"
          (touchstart)="addHoverClass(letter)"
          (touchend)="handleTouchEndNormalKey(letter.index, $event)"
          [disabled]="disableResponseButtons"
          *ngFor="let letter of middleKeyRow; index as $index">
          <div [ngClass]="{'keyboard-letters font-30' : startTask, 'keyboard-letters-hidden' : !startTask}">
            {{letter.text}}
          </div>
        </button>
        <button [ngClass]="{'hover': enter.highlight, 'keyboard-enter' : startTask, 'keyboard-enter-hidden' : !startTask, 'keyboard-enter-disabled' : targetWord.length === 0}"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          (click)="submitResponse(); removeHoverClass(enter)"
          (mouseenter)="addHoverClass(enter)" (mouseleave)="removeHoverClass(enter)"
          (touchstart)="addHoverClass(enter)"
          (touchend)="handleTouchEndSpecialKey(enter.keyCode, $event)"
          [disabled]="disableResponseButtons">
          <div [ngClass]="{'special-text right' : startTask, 'keyboard-letters-hidden' : !startTask}">
            {{enter.text}}
          </div>
        </button>
      </div>
      <div class="keyboard-row">
        <button
          [ngClass]="{
            'hover': shift.highlight, 
            'keyboard-shift' : startTask, 
            'keyboard-shift-hidden' : !startTask,
            'caps-lock-on': capsLockOn
          }"
          (click)="shiftLetters()"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          (mouseenter)="addHoverClass(shift)" (mouseleave)="removeHoverClass(shift)"
          (touchend)="handleTouchEndSpecialKey(shift.keyCode, $event)"
          [disabled]="disableResponseButtons">
          <div [ngClass]="{'special-text' : startTask, 'keyboard-letters-hidden' : !startTask}">
            {{shift.text}}
          </div>
        </button>
        <button [ngClass]="{'hover': letter.highlight, 'keyboard-key' : startTask, 'keyboard-key-hidden' : !startTask}"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          (click)="addLetter(letter.index);"
          (touchend)="handleTouchEndNormalKey(letter.index, $event)"
          [disabled]="disableResponseButtons"
          *ngFor="let letter of bottomKeyRow; index as $index">
          <div [ngClass]="{'keyboard-letters font-30' : startTask, 'keyboard-letters-hidden' : !startTask}">
            {{letter.text}}
          </div>
        </button>
        <button
          [ngClass]="{
            'hover': shift.highlight, 
            'keyboard-shift' : startTask, 
            'keyboard-shift-hidden' : !startTask,
            'caps-lock-on': capsLockOn
          }"
          (click)="shiftLetters()"
          [ngStyle]="{'border-color':' ' + interventionTaskService.highlightColor() + ' '}"
          (mouseenter)="addHoverClass(shift)" (mouseleave)="removeHoverClass(shift)"
          (touchend)="handleTouchEndSpecialKey(shift.keyCode, $event)"
          [disabled]="disableResponseButtons">
          <div [ngClass]="{'special-text right' : startTask, 'keyboard-letters-hidden' : !startTask}">
            {{shift.text}}
          </div>
        </button>
        <div class="spelling-empty-keyboard-space"></div>
      </div>
    </div>
  </div>
  <trial-counter [showCorrectIncorrect]="true" [taskAnimationComplete]="animationComplete"></trial-counter>
  <instructions [videoSource]="instructionalVideoFile"></instructions>
  <save-data-dialog (onSaveButtonClick)="saveTaskData()"></save-data-dialog>
  <try-again-dialog></try-again-dialog>
  <focus-dialog></focus-dialog>
  <div *ngIf="showCapsWarningDialog">
    <div class="caps-warning-opacity-layer"></div>
    <div class="caps-warning-dialog-background">
      <div class="caps-warning-dialog-window">
        <div class="caps-warning-dialog-text">
          You typed the word in
          all capital letters:
          <div class="caps-warning-current-response-text">
            {{capsWarningResponse}}
          </div>
          Do you want to continue?
        </div>
        <div class="caps-warning-dialog-button-container">
          <button class="caps-warning-dialog-cancel-button" (click)="hideCapsWarningDialog();"></button>
          <button class="caps-warning-dialog-ok-button" (click)="submitResponse();"></button>
        </div>
      </div>
    </div>
  </div>
</div>
